import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const SleepingWithGhosts = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/albums" noheader notopmargin >
      <SEO lang="en" title={'Sleeping With Ghosts'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'Sleeping with ghosts', 'Placebo']} description="Placebo albums, why they important for me. Why for some reason, depressing songs make it less depressing? Fight fire with fire.">
        </SEO>
        <HeroWithPhotoAndHeader backto="/albums" desc="How I feel when I hear Placebo songs. Especially Sleeping With Ghosts." imageURL={'sleepingwithghosts-placebo.jpeg'} heading="Sleeping With Ghosts by Placebo"/>
        <Section >
            <Paragraph>It seemed to me that it happened before. Rather, the memories are so unpleasant that I want to throw them even further into history.</Paragraph>
            <Paragraph>I sit in an unexpectedly soft seat of the minibus (it is also soft in the coffin), watching the rapidly changing landscapes in the window, from hill to hill. Drive back faster.</Paragraph>
            <Paragraph>It is dark outside. But this is not just the darkness of the evening city, because even the stars are visible! Flickering candlelight shines in rare windows, somewhere a strangely directed source of light, clearly a lantern.</Paragraph>
            <Paragraph>We are approaching the hospital stop. Further on foot, through the zigzags of an overgrown path. They usually don't come here, there is another passage, but it is for ambulances. This is a hospital. People come here if they cannot help in other hospitals.</Paragraph>
            <Paragraph>And then the stressful atmosphere of the hospital. This smell, doctors running, colors that should soothe. We sat and visited. He himself understood how unpleasant it was for us to be here and thanked us for coming.</Paragraph>
            <Paragraph>Then the road home, accompanied by the same songs, somewhat similar, but no less tragic. For some reason, depressing songs make it less depressing. Fight fire with fire.</Paragraph>
        </Section>

    </Layout>
  ); };

export default SleepingWithGhosts;
